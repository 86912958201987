import { HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { PendingRequestService } from 'src/app/shared/services/pending-request.service';

@Injectable()
export class PendingRequestInterceptor implements HttpInterceptor {
    constructor(
        private pendingRequestService: PendingRequestService
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.pendingRequestService.increment();

        return next.handle(request).pipe(
            finalize(() => this.pendingRequestService.decrement())
        )
    }
}