import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { PermissionsService } from '../shared/services/permissions.service';
import { AuthService, UserType } from '../modules/auth/services/auth.service';
import { ModuleAppccFeatures, ModuleHarvestFeatures, ModuleProductFeatures, ModuleVesselsFeatures } from '../modules/configuration/models/celler-configuration';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
    public model: any[] = [];
    public configOptions: any = {};
    public user: UserType = this.authService.currentUserValue;

    constructor(
        private permissionsService: PermissionsService,
        private authService: AuthService,
    ) {

    }

    ngOnInit() {
        this.permissionsService.getAllOptions().subscribe((options) => {
            this.configOptions = options;
            this.model = this._getMenu();
        });
    }

    private _getMenu() {
        // Menu
        let menu = [];
        let reportMenu = this._getReportMenu();

        if (this.user?.roles.includes('ROLE_SUPER_ADMIN') || this.user?.roles.includes('ROLE_ADMIN')) {
            menu.push({
                label: '',
                items: [
                    {
                        label: $localize`Inici`,
                        icon: 'pi pi-fw fa-solid fa-house',
                        routerLink: ['/dashboard'],
                        items: [
                            {
                                label: $localize`General`,
                                routerLink: ['/dashboard']
                            },
                            {
                                label: $localize`Verema`,
                                routerLink: ['/dashboard/harvest']
                            }
                        ]
                    }
                ]
            })

            // Check simple or advanced vineyard config options
            if (this.configOptions[ModuleHarvestFeatures.vineyardAdvancedeMode]) {
                let vineyardMenu = {
                    items: [
                        {
                            label: $localize`Finques i parcel·les`,
                            icon: 'pi pi-fw fa-solid fa-map-location',
                            routerLink: ['/vineyard/farms-and-parcels'],
                        },
                        {
                            label: $localize`Verema`,
                            icon: 'pi pi-fw fa-solid fa-tractor',
                            routerLink: ['/harvest'],
                            items: [
                                {
                                    label: $localize`Entrada de raïm`,
                                    routerLink: ['/harvest/receive-grape']
                                },
                                {
                                    label: $localize`Llista de pesades`,
                                    routerLink: ['/harvest/e-rvc']
                                },
                                {
                                    label: $localize`Estoc de raïm`,
                                    routerLink: ['/inventory/harvest']
                                },
                                {
                                    label: $localize`Subproductes`,
                                    routerLink: ['/sale/subproduct']
                                }
                            ]
                        }
                    ]
                }

                if (this.configOptions[ModuleHarvestFeatures.receiveTrailerMode]) {
                    vineyardMenu.items[1].items?.unshift({
                        label: $localize`Entrada de remolc`,
                        routerLink: ['/harvest/receive-trailer']
                    });
                }

                if (this.configOptions[ModuleHarvestFeatures.vineyardAnalysisMode]) {
                    vineyardMenu.items[1].items?.unshift({
                        label: $localize`Control de maduració`,
                        routerLink: ['/harvest/vineyard-analysis']
                    });
                }

                menu.push(vineyardMenu);
            }
            else {
                menu.push({
                    items: [
                        {
                            label: $localize`Verema`,
                            icon: 'pi pi-fw fa-solid fa-tractor',
                            routerLink: ['/harvest'],
                            items: [
                                {
                                    label: $localize`Estoc de raïm`,
                                    routerLink: ['/inventory/harvest']
                                },
                                {
                                    label: $localize`Subproductes`,
                                    routerLink: ['/sale/subproduct']
                                }
                            ]
                        }
                    ]
                })
            }

            // Check vesselManagement config options
            if (this.configOptions[ModuleVesselsFeatures.vesselsManagement]) {
                menu.push({
                    items: [
                        {
                            label: $localize`Dipòsits`,
                            icon: 'pi pi-fw fa-solid fa-glass-water',
                            items: [
                                {
                                    label: $localize`Instal·lacions`,
                                    routerLink: ['/vessel/grid']
                                },
                                {
                                    label: $localize`Most i doll sense dipòsit`,
                                    routerLink: ['/vessel/virtual']
                                },
                                {
                                    label: $localize`Mermes`,
                                    routerLink: ['winemaking/shrinkage']
                                }
                            ]
                        }
                    ]
                })
            }
            else {
                menu.push({
                    label: '',
                    items: [
                        {
                            label: $localize`Most`,
                            icon: 'pi pi-fw fa-solid fa-water',
                            routerLink: ['/inventory/grape-must'],
                        }
                    ]
                },
                    {
                        label: '',
                        items: [
                            {
                                label: $localize`Doll`,
                                icon: 'pi pi-fw fa-solid fa-glass-water',
                                routerLink: ['/inventory/bulk'],
                            }
                        ]
                    })
            }

            // Set bottling menu
            menu.push({
                items: [
                    {
                        label: $localize`Envasats`,
                        icon: 'pi pi-fw fa-solid fa-wine-bottle',
                        routerLink: ['/#'],
                        items: [
                            {
                                label: $localize`Embotellats`,
                                routerLink: ['/bottled/bottled-wine'],
                            },
                            {
                                label: $localize`Etiquetats`,
                                routerLink: ['/bottled/labelling'],
                            },
                            {
                                label: $localize`Ensamblatges`,
                                routerLink: ['/bottled/assembly'],
                            },
                            {
                                label: $localize`Marques comercials`,
                                routerLink: ['/bottled/commercial-brand'],
                            }
                        ]
                    }
                ]
            });

            // Set sales to menu
            menu.push({
                label: '',
                items: [
                    {
                        label: $localize`Vendes`,
                        icon: 'pi pi-fw fa-solid fa-file-invoice',
                        routerLink: ['/#'],
                        items: [
                            {
                                label: $localize`Producte etiquetat`,
                                routerLink: ['/sale/labelling'],
                            },
                            {
                                label: $localize`Producte embotellat`,
                                routerLink: ['/sale/bottled'],
                            },
                            {
                                label: $localize`Producte a doll`,
                                routerLink: ['/sale/bulk'],
                            }
                        ]
                    }
                ]
            });

            // Check dryGoodsManagement config options
            let dryGoodsMenu = {
                items: [
                    {
                        label: $localize`Productes`,
                        icon: 'pi pi-fw fa-solid fa-boxes-stacked',
                        routerLink: ['/#'],
                        items: [
                            {
                                label: $localize`Auxiliars`,
                                routerLink: ['/product/oenological'],
                            }
                        ]
                    }
                ]
            };
            if (this.configOptions[ModuleProductFeatures.dryGoodsManagement]) {
                dryGoodsMenu.items[0].items.push({
                    label: $localize`Matèria seca`,
                    routerLink: ['/product/dry-good'],
                });
            }
            if (this.configOptions[ModuleProductFeatures.qualityLabelManagement]) {
                dryGoodsMenu.items[0].items.push({
                    label: $localize`Distintius`,
                    routerLink: ['/product/quality-label'],
                })
            }
            menu.push(dryGoodsMenu);

            // Set contacts
            menu.push({
                items: [
                    {
                        label: $localize`Contactes`,
                        icon: 'pi pi-fw fa-solid fa-address-book',
                        routerLink: ['/contacts'],
                        items: [
                            {
                                label: $localize`Clients`,
                                routerLink: ['/contacts/clients']
                            },
                            {
                                label: $localize`Proveïdors`,
                                routerLink: ['/contacts/providers']
                            }
                        ],
                    }
                ]
            });

            // Check APPCC config options
            if (this.configOptions[ModuleAppccFeatures.appccCleaning] || this.configOptions[ModuleAppccFeatures.appccBottling] || this.configOptions[ModuleAppccFeatures.appccMaintenance]) {
                let appccMenu: any = {
                    items: [
                        {
                            label: $localize`APPCC`,
                            icon: 'pi pi-fw fa-solid fa-file-shield',
                            routerLink: ['/appcc'],
                            items: []
                        }
                    ]
                };
                if (this.configOptions[ModuleAppccFeatures.appccCleaning]) {
                    appccMenu.items[0].items.push({
                        label: $localize`Neteja`,
                        routerLink: ['/#'],
                        items: [
                            {
                                label: $localize`Prerequisits (PPR)`
                            },
                            {
                                label: $localize`Productes`,
                                routerLink: ['/appcc/cleaning/product'],
                            },
                            {
                                label: $localize`Instal·lacions i equips`,
                                routerLink: ['/appcc/cleaning/equipment'],
                            },
                            {
                                label: $localize`Registres`,
                                routerLink: ['/appcc/cleaning/register'],
                            }
                        ]
                    });
                }
                if (this.configOptions[ModuleAppccFeatures.appccBottling]) {
                    appccMenu.items[0].items.push({
                        label: $localize`Embotellats`,
                        routerLink: ['/#'],
                        items: [
                            {
                                label: $localize`Prerequisits (PPR)`
                            },
                            {
                                label: $localize`Registres`,
                                routerLink: ['/appcc/bottling/register'],
                            }
                        ]
                    })
                }
                if (this.configOptions[ModuleAppccFeatures.appccMaintenance]) {
                    appccMenu.items[0].items.push({
                        label: $localize`Manteniment`,
                        routerLink: ['/#'],
                        items: [
                            {
                                label: $localize`Prerequisits (PPR)`
                            },
                            {
                                label: $localize`Registres`,
                                routerLink: ['/appcc/maintenance/register'],
                            }
                        ]
                    })
                }

                menu.push(appccMenu);
            }

            // Set report menu
            menu.push(reportMenu);

            // Set beta features
            // menu.push({
            //     items: [
            //         {
            //             label: 'Beta',
            //             icon: 'pi pi-fw fa-solid fa-rocket',
            //             routerLink: ['/beta'],
            //             items: [
            //                 {
            //                     label: 'De la llibreta a eVins',
            //                     routerLink: ['/beta/notebook-to-evins'],
            //                 }
            //             ]
            //         }
            //     ]
            // });
        } else {
            // Set report menu
            menu.push(reportMenu);
        }

        return menu;
    }

    private _getReportMenu() {
        return {
            items: [
                {
                    label: $localize`Informes`,
                    icon: 'pi pi-fw fa-solid fa-book',
                    items: [
                        {
                            label: $localize`Llibres de registre`,
                            routerLink: ['/report/record-book'],
                        },
                        {
                            label: $localize`Declaracions`,
                            routerLink: ['/report/declaration'],
                        },
                        {
                            label: $localize`Traçabilitat`,
                            routerLink: ['/report/traceability'],
                        },
                        {
                            label: $localize`Resum de vendes`,
                            routerLink: ['/report/sales'],
                        },
                        {
                            label: $localize`Moviments`,
                            routerLink: ['/report/movements/all'],
                        },
                        {
                            label: $localize`DO Cava`,
                            routerLink: ['/#'],
                            items: [
                                {
                                    label: $localize`Tiratges`,
                                    routerLink: ['/report/do-cava/tirage'],
                                },
                                {
                                    label: $localize`Degorjats`,
                                    routerLink: ['/report/do-cava/disgorgement'],
                                },
                                {
                                    label: $localize`Exportacions i mercat nacional`,
                                    routerLink: ['/report/do-cava/sales'],
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    }
}
