import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiCollectionResponse } from '../models/api-collection-response.model';
import { Celler } from '../models/celler.model';


@Injectable({
    providedIn: 'root'
})
export class CellersService {

    private serviceUrl = `${environment.apiUrl}/cellers`

    constructor(
        private httpClient: HttpClient,
    ) { }

    index(query?: string): Observable<ApiCollectionResponse<Celler>> {
        const queryParams = (query) ? `?${query}` : '';
        return this.httpClient.get<ApiCollectionResponse<Celler>>(`${this.serviceUrl}${queryParams}`).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    view(id: number): Observable<Celler> {
        return this.httpClient.get<Celler>(`${this.serviceUrl}/${id}`).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    update(id: number, data: Celler): Observable<Celler> {
        return this.httpClient.put<Celler>(`${this.serviceUrl}/${id}/settings`, data).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }
}
