export class CellerConfiguration {
    public '@id': string;
    public id: number;
    public name: string;
    public value: string | boolean;

    constructor(name?: string | null) {
        if (name) this.name = name;
    }
}

export enum CellerConfigurationOptions {
    general_configuration = 'general_configuration',
    ervc_credentials = 'ervc_credentials',
    factusol_credentials = 'factusol_credentials',
    holded_credentials = 'holded_credentials',
    ccpae = 'ccpae',
    module_harvest = 'module_harvest',
    module_product = 'module_product',
    module_appcc = 'module_appcc',
    module_vesel = 'module_vesel',
    module_report = 'module_report'
}

export enum ModuleHarvestFeatures {
    vineyardAdvancedeMode = 'vineyardAdvancedeMode',
    vineyardAnalysisMode = 'vineyardAnalysisMode',
    receiveTrailerMode = 'receiveTrailerMode',
}

export enum ModuleProductFeatures {
    dryGoodsManagement = 'dryGoodsManagement',
    qualityLabelManagement = 'qualityLabelManagement'
}

export enum ModuleAppccFeatures {
    appccBottling = 'appccBottling',
    appccCleaning = 'appccCleaning',
    appccMaintenance = 'appccMaintenance'
}

export enum ModuleVesselsFeatures {
    vesselsManagement = 'vesselsManagement'
}

export enum ModuleReportFeatures {
    dav = 'dav',
    emcs = 'emcs'
}