import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    private key = 'DPJ6wk$4oi0Umt@@d1KmZ3W3E0I&VXptl7J2R8cY0vu*eh$e6d';

    constructor() { }

    public setData(key: string, value: string) {
        localStorage.setItem(key, this._encrypt(value));
    }

    public getData(key: string) {
        let data = localStorage.getItem(key) || '';
        return this._decrypt(data);
    }

    public removeData(key: string) {
        return localStorage.removeItem(key);
    }

    public clearData() {
        return localStorage.clear();
    }

    private _encrypt(txt: string): string {
        return CryptoJS.AES.encrypt(txt, this.key).toString();
    }

    private _decrypt(txtToDecrypt: string) {
        return CryptoJS.AES.decrypt(txtToDecrypt, this.key).toString(CryptoJS.enc.Utf8);
    }
}
