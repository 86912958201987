import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AgencyGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isReportRoute = route.url.map((segment) => segment.path).join('/') === 'report';
    const currentUser = this.authService.currentUserValue;

    if (currentUser) {
        if(!currentUser.roles.includes('ROLE_USER')) {
            return true;
        }else if (currentUser.roles.includes('ROLE_USER') && isReportRoute) {
            return true;
        } else if (!currentUser.roles.includes('ROLE_USER') && !isReportRoute) {
            return true;
        }
    }

    return false;
  }
}
